import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ItineraryState, ItineraryDay} from './types';

const initialState = {
  listItineraryDays: null,
  currentId: 1,
} as ItineraryState;

const itinerarySlice = createSlice({
  name: 'itinerary',
  initialState,
  reducers: {
    setItinerary(state: ItineraryState, action: PayloadAction<ItineraryState>) {
      return Object.assign({}, action.payload);
    },
    setListItems(state: ItineraryState, action: PayloadAction<Array<ItineraryDay> | null>) {
      return Object.assign(state, {listItineraryDays: action.payload});
    },
    addItem(state: ItineraryState, action: PayloadAction<ItineraryDay>) {
      const existed = state?.listItineraryDays && state?.listItineraryDays.find(item => item?.id === action.payload?.id);
      if (existed) return state;
      return Object.assign(state, {
        listItineraryDays: [...(state?.listItineraryDays || []), action.payload],
        currentId: +state.currentId + 1,
      });
    },
    setItem(state: ItineraryState, action: PayloadAction<ItineraryDay>) {
      if (!state?.listItineraryDays) return state;
      const updatedIndex = state?.listItineraryDays && state?.listItineraryDays.findIndex(item => item?.id === action.payload?.id);
      const newList = (state?.listItineraryDays || []).slice();
      newList[updatedIndex] = action.payload;
      return Object.assign(state, {
        listItineraryDays: newList,
      });
    },
    removeITem(state: ItineraryState, action: PayloadAction<string | number>) {
      if (!state?.listItineraryDays) return state;
      const newList = state?.listItineraryDays.slice().filter(img => img?.id !== action.payload);
      return Object.assign(state, {
        listItineraryDays: newList,
      });
    },
    reset(state: ItineraryState) {
      return initialState;
    },
    setCurrentId(state: ItineraryState, action: PayloadAction<string | number>) {
      return Object.assign(state, {
        currentId: action.payload,
      });
    },
  },
});

export const {setListItems, addItem, removeITem, setItinerary, reset, setItem, setCurrentId} = itinerarySlice.actions;
export default itinerarySlice.reducer;
