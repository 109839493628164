import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {UserStoreState} from './types';
import {getUser} from '@services/userStorage';

const initialState = getUser() || ({email: null, token: null} as UserStoreState);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state: UserStoreState, action: PayloadAction<UserStoreState>) {
      return Object.assign({}, action.payload);
    },
    setToken(state: UserStoreState, action: PayloadAction<string | undefined>) {
      return Object.assign(state, {token: action.payload});
    },
  },
});

export const {setUser, setToken} = userSlice.actions;
export default userSlice.reducer;
