import React from 'react';
import ModalContainer from './ModalContainer';
import {UploadGallery} from '@components/ImageGallery';
import {Dialog} from '@headlessui/react';
import {createTag} from '@services/tag';
import {useAddTag} from '@services/useReactQuery';
import {useEditImageModal} from '@store/EditImageModal/hooks';
import {useGallerySelectedImages} from '@store/Gallery/hooks';

function ManageTag() {
  const {isOpen, openModal, currentImage, setCurrentImage, setModal, closeModal} = useEditImageModal();

  const [targetImg, setTargetImg] = React.useState<any>();
  const [description, setDescription] = React.useState<string>();
  const [alt, setAlt] = React.useState<string>();
  const [source, setSource] = React.useState<string>();
  const {update: updateSelectedImage} = useGallerySelectedImages();
  React.useEffect(() => {
    if (currentImage?.description) {
      try {
        const desObj = JSON.parse(currentImage?.description);
        desObj?.alt && !alt && setAlt(desObj?.alt);
        desObj?.source && !source && setSource(desObj?.source);
        desObj?.description && !description && setDescription(desObj?.description);
      } catch (err) {}
    }
  }, [alt, currentImage?.description, description, source]);
  console.log('RENDER');
  const handleAction = {
    closeModal: () => {
      closeModal();
    },
    onDescriptionChange: (e: any) => {
      setDescription(e.currentTarget.value);
    },
    onAltChange: (e: any) => {
      setAlt(e.currentTarget.value);
    },
    onSourceChange: (e: any) => {
      setSource(e.currentTarget.value);
    },

    addInfo: () => {
      currentImage && updateSelectedImage(Object.assign({}, currentImage, {description: JSON.stringify({alt, source, description})}));
      closeModal();
    },
    handleImageLoaded: ({target}: {target: any}) => {
      setTargetImg(target);
    },
  };
  return (
    <ModalContainer isOpen={isOpen} setIsOpen={setModal} textClassName='w-full max-w-2xl z-[400]' wrapperClassName='z-[300]'>
      <div className=''>
        <Dialog.Title
          as='h3'
          className='text-sm font-medium leading-6 text-gray-900 bg-[#f6f6f9] px-6 py-4 flex justify-between items-center'
        >
          Edit {currentImage?.name || 'Image'}
          <button aria-disabled='false' type='button' className='p-2 border rounded' onClick={handleAction.closeModal}>
            <svg
              width='1em'
              height='1em'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              aria-hidden='true'
              focusable='false'
            >
              <path
                d='M24 2.417L21.583 0 12 9.583 2.417 0 0 2.417 9.583 12 0 21.583 2.417 24 12 14.417 21.583 24 24 21.583 14.417 12 24 2.417z'
                fill='#212134'
              ></path>
            </svg>
          </button>
        </Dialog.Title>
        <div className='flex gap-4 p-4 justify-between items-stretch'>
          <div className='w-1/2 bg-preview flex justify-between items-center flex-1'>
            <img
              src={currentImage?.src}
              alt={currentImage?.name || 'Image'}
              height={50}
              width={600}
              className='object-fit'
              onLoad={handleAction.handleImageLoaded}
            />
          </div>

          <div className='w-1/2 space-y-5 flex flex-col'>
            <div className='text-xs rounded bg-gray-100 px-4 py-2 divide-gray-800 divide-y-2 space-y-3'>
              <div className='text-center font-semibold text-sm'>Info</div>
              <div className='space-y-2 pt-3'>
                <div className='grid grid-cols-2'>
                  <span className='font-semibold'>Name</span>
                  <span className='break-words'>{currentImage?.name}</span>
                </div>
                <div className='grid grid-cols-2'>
                  <span className='font-semibold'>Natural Height</span>
                  <span>{targetImg?.naturalHeight} pixels</span>
                </div>
                <div className='grid grid-cols-2'>
                  <span className='font-semibold'>Natural Width</span>
                  <span>{targetImg?.naturalWidth} pixels</span>
                </div>
              </div>
            </div>
            <div className='text-xs rounded bg-gray-100 py-2 px-4 space-y-3 flex-1 flex flex-col'>
              <div className='font-medium text-xs'>Add Image Atl</div>
              <input
                className='w-full flex-1 rounded text-xs px-2 py-1'
                value={alt}
                onChange={handleAction.onAltChange}
                placeholder='Enter the image alt'
                autoFocus
              />
            </div>
            <div className='text-xs rounded bg-gray-100 py-2 px-4 space-y-3 flex-1 flex flex-col'>
              <div className='font-medium text-xs'>Add Image Source</div>
              <input
                className='w-full flex-1 rounded text-xs px-2 py-1'
                value={source}
                onChange={handleAction.onSourceChange}
                placeholder='Enter the image source'
                autoFocus
              />
            </div>
            <div className='text-xs rounded bg-gray-100 py-2 px-4 space-y-3 flex-1 flex flex-col'>
              <div className='font-medium text-xs'>Add description</div>
              <textarea
                className='w-full flex-1 rounded text-xs p-4'
                value={description}
                onChange={handleAction.onDescriptionChange}
                placeholder='Enter the image description'
                autoFocus
              />
            </div>
          </div>
        </div>
        <div className='px-6 py-4 bg-[#f6f6f9] flex justify-between items-center'>
          <button
            aria-disabled='false'
            type='button'
            className='py-2 px-4 border rounded flex items-center bg-white hover:bg-transparent'
            onClick={handleAction.closeModal}
          >
            <span className='text-xs font-semibold'>Cancel</span>
          </button>
          <button
            aria-disabled='false'
            type='button'
            className={'py-2 px-4 border rounded flex items-center bg-blue-600 text-white hover:bg-blue-700'}
            onClick={handleAction.addInfo}
          >
            <span className='text-xs font-semibold'>Add Information</span>
          </button>
        </div>
      </div>
    </ModalContainer>
  );
}

export default ManageTag;
