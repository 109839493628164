import React, {Suspense} from 'react';
import {BrowserRouter} from 'react-router-dom';
import {motion} from 'framer-motion';
import {LoadingScreen} from '@components/LoadingScreen';
import Providers from '@/providers';
import {RenderRoutes} from '@/routes';
import ModalEditImage from '@components/Modal/ModalEditImage';

const App: React.FC = () => {
  return (
    <motion.div
      initial='pageInitial'
      animate='pageAnimate'
      transition={{duration: 1, easing: 'easeInOut'}}
      variants={{
        pageInitial: {
          opacity: 0,
        },
        pageAnimate: {
          opacity: 1,
        },
      }}
    >
      <Suspense fallback={<LoadingScreen />}>
        <BrowserRouter>
          <RenderRoutes />
          <ModalEditImage />
        </BrowserRouter>
      </Suspense>
    </motion.div>
  );
};

export default App;
