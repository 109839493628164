import {SelectValue} from '@components/Select/types';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {CategoryStoreState} from './types';

const initialState: CategoryStoreState = {
  listCategories: null,
  currentCategory: null,
};

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    setCategoryStore(state: CategoryStoreState, action: PayloadAction<CategoryStoreState>) {
      return action.payload;
    },
    setCategories(state: CategoryStoreState, action: PayloadAction<Array<SelectValue> | null>) {
      return Object.assign(state, {
        listCategories: action.payload,
      });
    },
    setCurrentCategory(state: CategoryStoreState, action: PayloadAction<SelectValue | null>) {
      return Object.assign(state, {
        currentCategory: action.payload,
      });
    },
    addCategory(state: CategoryStoreState, action: PayloadAction<SelectValue>) {
      const _list = (state?.listCategories || []).slice();
      return Object.assign(state, {
        listCategories: [..._list, action.payload],
        currentCategory: action.payload,
      });
    },
    removeCategory(state: CategoryStoreState, action: PayloadAction<string | number>) {
      const _list = (state?.listCategories || []).slice();
      const exited = state?.currentCategory?.id === action.payload;
      return Object.assign(state, {
        listCategories: _list.filter(item => item.id !== action.payload),
        currentCategory: exited ? null : state?.currentCategory,
      });
    },
    reset() {
      return initialState;
    },
  },
});

export const {setCategoryStore, addCategory, removeCategory, reset, setCategories, setCurrentCategory} = categorySlice.actions;
export default categorySlice.reducer;
