import {useAppSelector, useAppDispatch} from '../hooks';
import {setCurrentImage, setModal} from './index';
import {CurrentImageState, EditImageModalStoreState} from './types';

export const useSelectedImages = () => {
  return useAppSelector(state => state?.gallery?.mode);
};

export const useEditImageModal = (): {
  currentImage: CurrentImageState | null;
  isOpen: boolean;
  setModal: (show: boolean) => void;
  closeModal: () => void;
  openModal: () => void;
  reset: () => void;
  setCurrentImage: (currentImage: CurrentImageState | null) => void;
} => {
  const dispatch = useAppDispatch();
  return {
    currentImage: useAppSelector(state => state?.editImageModal?.currentImage),
    setModal: (show: boolean) => {
      dispatch(setModal(show));
    },
    isOpen: useAppSelector(state => state?.editImageModal?.isOpen),
    openModal: () => {
      dispatch(setModal(true));
    },
    closeModal: () => {
      dispatch(setModal(false));
    },
    setCurrentImage: (currentImage: CurrentImageState | null) => {
      dispatch(setCurrentImage(currentImage));
    },
    reset: () => {
      dispatch(setModal(false));
      dispatch(setCurrentImage(null));
    },
  };
};
