import {configureStore} from '@reduxjs/toolkit';
import UserReducer from '@store/User';
import GalleryReducer from '@store/Gallery';
import PaginationReducer from '@store/Pagination';
import DeparturePriceReducer from '@store/DeparturePrice';
import TagReducer from '@store/Tag';
import CategoryReducer from '@store/Category';
import TourMetaReducer from '@store/TourMeta';
import EditableContentReducer from '@store/EditableContent';
import EditImageModalReducer from '@store/EditImageModal';
import ItineraryReducer from '@store/Itinerary';

const store = configureStore({
  reducer: {
    user: UserReducer,
    gallery: GalleryReducer,
    pagination: PaginationReducer,
    departurePrice: DeparturePriceReducer,
    tag: TagReducer,
    category: CategoryReducer,
    tourMeta: TourMetaReducer,
    editableContent: EditableContentReducer,
    editImageModal: EditImageModalReducer,
    itinerary: ItineraryReducer,
  },
});
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
