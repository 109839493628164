import React, {Component, ErrorInfo, ReactNode} from 'react';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  errorMessage?: string;
}

class ErrorBoundaryProvider extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return {hasError: true};
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      hasError: true,
      errorMessage: errorInfo.componentStack,
    });
  }

  public render() {
    if (this.state.hasError) {
      return (
        <>
          <h1>Sorry.. there was an error</h1>
          <h2>Something went wrong.</h2>
          <details className='whitespace-pre-wrap'>{this.state.errorMessage}</details>
        </>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundaryProvider;
