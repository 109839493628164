import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import produce from 'immer';

import {TourMetaStoreState, ExclusionItem, InclusionItem} from './types';
import * as _ from 'lodash';

const initialState = {
  listInclusions: null,
  listExclusions: null,
  currentId: 1,
} as TourMetaStoreState;

const tourMetaSlice = createSlice({
  name: 'tourMeta',
  initialState,
  reducers: {
    setTourMeta(state: TourMetaStoreState, action: PayloadAction<TourMetaStoreState>) {
      return Object.assign({}, action.payload);
    },
    setListInclusion(state: TourMetaStoreState, action: PayloadAction<Array<InclusionItem> | null>) {
      return Object.assign(state, {listInclusions: action.payload});
    },
    setListExclusion(state: TourMetaStoreState, action: PayloadAction<Array<ExclusionItem> | null>) {
      return Object.assign(state, {listExclusions: action.payload});
    },
    setExclusionItem(state: TourMetaStoreState, action: PayloadAction<ExclusionItem>) {
      const listExclusions = _.get(state, 'listExclusions');
      if (!listExclusions) return;
      const index = listExclusions.findIndex(item => item?.id === action.payload.id);
      if (!index) return;
      const newList = listExclusions.slice();
      newList[index] = action.payload;
      return Object.assign(state, {
        listExclusions: newList,
      });
    },
    setInclusionItem(state: TourMetaStoreState, action: PayloadAction<InclusionItem>) {
      const listInclusions = _.get(state, 'listInclusions');
      if (!listInclusions) return;
      const index = listInclusions.findIndex(item => item?.id === action.payload.id);
      if (index === -1) return;
      const newList = listInclusions.slice();
      newList[index] = action.payload;
      return Object.assign(state, {
        listInclusions: newList,
      });
    },
    addInclusionItem(state: TourMetaStoreState, action: PayloadAction<InclusionItem>) {
      return Object.assign(state, {
        listInclusions: [...(state?.listInclusions || []), action.payload],
        currentId: +state.currentId + 1,
      });
    },
    addExclusionItem(state: TourMetaStoreState, action: PayloadAction<ExclusionItem>) {
      return Object.assign(state, {
        listExclusions: [...(state?.listExclusions || []), action.payload],
        currentId: +state.currentId + 1,
      });
    },
    removeInclusionItem(state: TourMetaStoreState, action: PayloadAction<string | number>) {
      if (!state?.listInclusions) return state;
      const newList = state?.listInclusions.slice().filter(img => img?.id !== action.payload);
      return Object.assign(state, {
        listInclusions: newList,
      });
    },
    removeExclusionItem(state: TourMetaStoreState, action: PayloadAction<string | number>) {
      if (!state?.listExclusions) return state;
      const newList = state?.listExclusions.slice().filter(img => img?.id !== action.payload);
      return Object.assign(state, {
        listExclusions: newList,
      });
    },
    reset(state: TourMetaStoreState) {
      return initialState;
    },
    setCurrentId(state: TourMetaStoreState, action: PayloadAction<string | number>) {
      return Object.assign(state, {
        currentId: action.payload,
      });
    },
  },
});

export const {
  addInclusionItem,
  addExclusionItem,
  removeInclusionItem,
  setTourMeta,
  reset,
  removeExclusionItem,
  setCurrentId,
  setListInclusion,
  setListExclusion,
  setExclusionItem,
  setInclusionItem,
} = tourMetaSlice.actions;
export default tourMetaSlice.reducer;
