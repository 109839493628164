import {Provider} from 'react-redux';
import store from '@/store';
import {FCProps} from '@interfaces/component';

const Providers = (props: FCProps): JSX.Element => {
  const {children} = props;
  return <Provider store={store}>{children}</Provider>;
};

export default Providers;
