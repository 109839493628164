import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ContentState, EditableContentState} from './types';

const initialState: EditableContentState = {editorState: null, content: null};

const editableContentSlice = createSlice({
  name: 'editableContent',
  initialState,
  reducers: {
    setContent(state: EditableContentState, action: PayloadAction<ContentState>) {
      return Object.assign(state, {content: action.payload});
    },
    setEditorState(state: EditableContentState, action: PayloadAction<any>) {
      return Object.assign(state, {editorState: action.payload});
    },
    reset() {
      return initialState;
    },
  },
});

export const {setContent, setEditorState, reset} = editableContentSlice.actions;
export default editableContentSlice.reducer;
