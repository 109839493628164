import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {PaginationStoreState} from './types';

const initialState: PaginationStoreState = {page: 1, limit: 9};

const paginationSlice = createSlice({
  name: 'pagination',
  initialState,
  reducers: {
    setPagination(state: PaginationStoreState, action: PayloadAction<PaginationStoreState>) {
      return Object.assign({}, action.payload);
    },
    setPage(state: PaginationStoreState, action: PayloadAction<string | number>) {
      return Object.assign(state, {page: action.payload});
    },
    setLimit(state: PaginationStoreState, action: PayloadAction<string | number>) {
      return Object.assign(state, {limit: action.payload});
    },
    reset() {
      return initialState;
    },
  },
});

export const {setPagination, setPage, setLimit, reset} = paginationSlice.actions;
export default paginationSlice.reducer;
