import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {DeparturePriceStoreState, IDeparturePrice} from './types';

const initialState = {
  listPrices: null,
  currentId: 1,
} as DeparturePriceStoreState;

const departurePriceSlice = createSlice({
  name: 'departurePrice',
  initialState,
  reducers: {
    setDeparturePrice(state: DeparturePriceStoreState, action: PayloadAction<DeparturePriceStoreState>) {
      return Object.assign({}, action.payload);
    },
    setListItems(state: DeparturePriceStoreState, action: PayloadAction<Array<IDeparturePrice> | null>) {
      return Object.assign(state, {listPrices: action.payload});
    },
    addItem(state: DeparturePriceStoreState, action: PayloadAction<IDeparturePrice>) {
      const existed =
        state?.listPrices &&
        state?.listPrices.find(
          item =>
            item?.id === action.payload?.id &&
            item?.departure_date === action.payload?.departure_date &&
            item?.price === action.payload?.price,
        );
      if (existed) return state;
      return Object.assign(state, {
        listPrices: [...(state?.listPrices || []), action.payload],
        currentId: +state.currentId + 1,
      });
    },
    setItem(state: DeparturePriceStoreState, action: PayloadAction<IDeparturePrice>) {
      if (!state?.listPrices) return state;
      const updatedIndex = state?.listPrices && state?.listPrices.findIndex(item => item?.id === action.payload?.id);
      const newList = (state?.listPrices || []).slice();
      newList[updatedIndex] = action.payload;
      return Object.assign(state, {
        listPrices: newList,
      });
    },
    removeITem(state: DeparturePriceStoreState, action: PayloadAction<string | number>) {
      if (!state?.listPrices) return state;
      const newList = state?.listPrices.slice().filter(img => img?.id !== action.payload);
      return Object.assign(state, {
        listPrices: newList,
      });
    },
    reset(state: DeparturePriceStoreState) {
      return initialState;
    },
    setCurrentId(state: DeparturePriceStoreState, action: PayloadAction<string | number>) {
      return Object.assign(state, {
        currentId: action.payload,
      });
    },
  },
});

export const {setListItems, addItem, removeITem, setDeparturePrice, reset, setItem, setCurrentId} = departurePriceSlice.actions;
export default departurePriceSlice.reducer;
