import {lazy} from 'react';
import {RouteType} from './types';

const LayoutLazy = lazy(() => import('@components/Layout/Layout'));
const AuthLayoutLazy = lazy(() => import('@components/Layout/AuthLayout'));

const HomePageLazy = lazy(() => import('@pages/homepage'));
const LoginPageLazy = lazy(() => import('@pages/auth/login'));
const ExamplePageLazy = lazy(() => import('@pages/Example1'));

const WrapConfigLazy = lazy(() => import('@pages/configs/index'));
const ListConfigLazy = lazy(() => import('@pages/configs/Manage'));
const EditConfigLazy = lazy(() => import('@pages/configs/Edit'));
const CreateConfigLazy = lazy(() => import('@pages/configs/Create'));

const WrapUserLazy = lazy(() => import('@pages/users/index'));
const ManageUSerLazy = lazy(() => import('@pages/users/manage'));
const EditUserLazy = lazy(() => import('@pages/users/edit'));
const CreateUserLazy = lazy(() => import('@pages/users/create'));

const WrapTourLazy = lazy(() => import('@pages/tours/index'));
const ManageTourLazy = lazy(() => import('@pages/tours/manage'));
const EditTourLazy = lazy(() => import('@pages/tours/edit'));
const CreateTourLazy = lazy(() => import('@pages/tours/create'));

const WrapComboLazy = lazy(() => import('@pages/comboes/index'));
const ManageComboLazy = lazy(() => import('@pages/comboes/manage'));
const EditComboLazy = lazy(() => import('@pages/comboes/edit'));
const CreateComboLazy = lazy(() => import('@pages/comboes/create'));

const WrapMediaLazy = lazy(() => import('@pages/media/index'));
const ManageMediaLazy = lazy(() => import('@pages/media/manage'));

const WrapTourCollectionLazy = lazy(() => import('@pages/tour-collections/index'));
const ManageTourCollectionLazy = lazy(() => import('@pages/tour-collections/manage'));
const EditTourCollectionLazy = lazy(() => import('@pages/tour-collections/edit'));
const CreateTourCollectionLazy = lazy(() => import('@pages/tour-collections/create'));
const WrapPostCollectionLazy = lazy(() => import('@pages/post-collections/index'));
const ManagePostCollectionLazy = lazy(() => import('@pages/post-collections/manage'));
const EditPostCollectionLazy = lazy(() => import('@pages/post-collections/edit'));
const CreatePostCollectionLazy = lazy(() => import('@pages/post-collections/create'));

const WrapPostLazy = lazy(() => import('@pages/posts/index'));
const ManagePostLazy = lazy(() => import('@pages/posts/manage'));
const EditPostLazy = lazy(() => import('@pages/posts/edit'));
const CreatePostLazy = lazy(() => import('@pages/posts/create'));

const WrapTourCategoryLazy = lazy(() => import('@pages/tour-categories/index'));
const ManageTourCategoryLazy = lazy(() => import('@pages/tour-categories/manage'));
const EditTourCategoryLazy = lazy(() => import('@pages/tour-categories/edit'));
const CreateTourCategoryLazy = lazy(() => import('@pages/tour-categories/create'));

const WrapPostCategoryLazy = lazy(() => import('@pages/post-categories/index'));
const ManagePostCategoryLazy = lazy(() => import('@pages/post-categories/manage'));
const EditPostCategoryLazy = lazy(() => import('@pages/post-categories/edit'));
const CreatePostCategoryLazy = lazy(() => import('@pages/post-categories/create'));

const WrapBannerLazy = lazy(() => import('@pages/banners/index'));
const ManageBannerLazy = lazy(() => import('@pages/banners/manage'));
const EditBannerLazy = lazy(() => import('@pages/banners/edit'));
const CreateBannerLazy = lazy(() => import('@pages/banners/create'));

const WrapOrderLazy = lazy(() => import('@pages/orders/index'));
const ManageOrderLazy = lazy(() => import('@pages/orders/manage'));
const EditOrderLazy = lazy(() => import('@pages/orders/edit'));

const ListRoutes: RouteType[] = [
  {
    path: '/login',
    key: 'Auth',
    element: <AuthLayoutLazy />,
    isProtected: false,
    children: [
      {
        path: '',
        key: 'homepage',
        element: <LoginPageLazy />,
        // isProtected: true,
      },
    ],
  },
  {
    path: '/',
    key: 'Layout',
    element: <LayoutLazy />,
    isProtected: true,
    children: [
      {
        path: '',
        key: 'homepage',
        element: <HomePageLazy />,
        // isProtected: true,
      },
      {
        path: 'example',
        key: 'example',
        element: <ExamplePageLazy />,
        // isProtected: true,
      },
      {
        path: '/configs',
        key: 'manage-config',
        element: <WrapConfigLazy />,
        children: [
          {
            path: '',
            element: <ListConfigLazy />,
          },
          {
            path: ':id',
            element: <EditConfigLazy />,
          },
          {
            path: 'create',
            element: <CreateConfigLazy />,
          },
        ],
        isProtected: true,
      },
      {
        path: '/users',
        key: 'manage-users',
        element: <WrapUserLazy />,
        children: [
          {
            path: '',
            element: <ManageUSerLazy />,
          },
          {
            path: ':id',
            element: <EditUserLazy />,
          },
          {
            path: 'create',
            element: <CreateUserLazy />,
          },
        ],
        isProtected: true,
      },
      {
        path: '/tours',
        key: 'manage-tours',
        element: <WrapTourLazy />,
        children: [
          {
            path: '',
            element: <ManageTourLazy />,
          },
          {
            path: ':id',
            element: <EditTourLazy />,
          },
          {
            path: 'create',
            element: <CreateTourLazy />,
          },
        ],
        isProtected: true,
      },
      {
        path: '/comboes',
        key: 'manage-comboes',
        element: <WrapComboLazy />,
        children: [
          {
            path: '',
            element: <ManageComboLazy />,
          },
          {
            path: ':id',
            element: <EditComboLazy />,
          },
          {
            path: 'create',
            element: <CreateComboLazy />,
          },
        ],
        isProtected: true,
      },
      {
        path: '/tour-collections',
        key: 'manage-tour-collections',
        element: <WrapTourCollectionLazy />,
        children: [
          {
            path: '',
            element: <ManageTourCollectionLazy />,
          },
          {
            path: ':id',
            element: <EditTourCollectionLazy />,
          },
          {
            path: 'create',
            element: <CreateTourCollectionLazy />,
          },
        ],
        isProtected: true,
      },
      {
        path: '/post-collections',
        key: 'manage-post-collections',
        element: <WrapPostCollectionLazy />,
        children: [
          {
            path: '',
            element: <ManagePostCollectionLazy />,
          },
          {
            path: ':id',
            element: <EditPostCollectionLazy />,
          },
          {
            path: 'create',
            element: <CreatePostCollectionLazy />,
          },
        ],
        isProtected: true,
      },
      {
        path: '/media-library',
        key: 'manage-media-library',
        element: <ManageMediaLazy />,
      },
      {
        path: '/posts',
        key: 'manage-posts',
        element: <WrapPostLazy />,
        children: [
          {
            path: '',
            element: <ManagePostLazy />,
          },
          {
            path: ':id',
            element: <EditPostLazy />,
          },
          {
            path: 'create',
            element: <CreatePostLazy />,
          },
        ],
        isProtected: true,
      },
      {
        path: '/tour-categories',
        key: 'manage-tour-categories',
        element: <WrapTourCategoryLazy />,
        children: [
          {
            path: '',
            element: <ManageTourCategoryLazy />,
          },
          {
            path: ':id',
            element: <EditTourCategoryLazy />,
          },
          {
            path: 'create',
            element: <CreateTourCategoryLazy />,
          },
        ],
        isProtected: true,
      },
      {
        path: '/banners',
        key: 'manage-banners',
        element: <WrapBannerLazy />,
        children: [
          {
            path: '',
            element: <ManageBannerLazy />,
          },
          {
            path: ':id',
            element: <EditBannerLazy />,
          },
          {
            path: 'create',
            element: <CreateBannerLazy />,
          },
        ],
        isProtected: true,
      },
      {
        path: '/orders',
        key: 'manage-orders',
        element: <WrapOrderLazy />,
        children: [
          {
            path: '',
            element: <ManageOrderLazy />,
          },
          {
            path: ':id',
            element: <EditOrderLazy />,
          },
        ],
        isProtected: true,
      },
      {
        path: '/post-categories',
        key: 'manage-post-categories',
        element: <WrapPostCategoryLazy />,
        children: [
          {
            path: '',
            element: <ManagePostCategoryLazy />,
          },
          {
            path: ':id',
            element: <EditPostCategoryLazy />,
          },
          {
            path: 'create',
            element: <CreatePostCategoryLazy />,
          },
        ],
        isProtected: true,
      },
    ],
  },
];

export default ListRoutes;
