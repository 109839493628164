import {get, set, remove} from './_localStorage';
import {UserStoreState} from '@store/User/types';

export const getUser = () => {
  const userStored = get('user');
  return JSON.parse(userStored);
};

export const storeUser = (user: UserStoreState | any) => {
  const oldUser = getUser() || {};
  const newUser = Object.assign(oldUser, user);
  set('user', JSON.stringify(newUser));
};
export const deleteUser = () => remove('user');
