import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {TagStoreState, TagState} from './types';

const initialState: TagStoreState = {listTags: []};

const tagSlice = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    setTags(state: TagStoreState, action: PayloadAction<Array<TagState>>) {
      return Object.assign({}, {listTags: action.payload});
    },
    addTag(state: TagStoreState, action: PayloadAction<TagState>) {
      const _list = state?.listTags.slice();
      return Object.assign(state, {listTags: [..._list, action.payload]});
    },
    removeTag(state: TagStoreState, action: PayloadAction<TagState>) {
      const _list = state?.listTags.slice();

      return Object.assign(state, {
        listTags: _list.filter(item => item.title !== action.payload?.title),
      });
    },
    reset() {
      return initialState;
    },
  },
});

export const {setTags, addTag, removeTag, reset} = tagSlice.actions;
export default tagSlice.reducer;
