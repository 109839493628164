import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {GalleryStoreState, GalleryMode, GallerySelectedImage} from './types';
import * as _ from 'lodash';

const initialState = {
  selectedImages: [],
  mode: 'display',
} as GalleryStoreState;

const gallerySlice = createSlice({
  name: 'gallery',
  initialState,
  reducers: {
    setGallery(state: GalleryStoreState, action: PayloadAction<GalleryStoreState>) {
      return Object.assign({}, action.payload);
    },
    setSelectedImages(state: GalleryStoreState, action: PayloadAction<Array<GallerySelectedImage>>) {
      return Object.assign(state, {selectedImages: action.payload});
    },
    addSelectedImages(state: GalleryStoreState, action: PayloadAction<GallerySelectedImage>) {
      return Object.assign(state, {
        selectedImages: [...state?.selectedImages, action.payload],
      });
    },
    updateImage(state: GalleryStoreState, action: PayloadAction<GallerySelectedImage>) {
      // return Object.assign(state, {
      //   selectedImages: [...state?.selectedImages, action.payload],
      // });
      if (!state.selectedImages) return;
      const index = state?.selectedImages.findIndex(item => item?.id === action.payload?.id);
      if (index < 0) return;
      state.selectedImages[index] = action.payload;
    },
    removeSelectedImages(state: GalleryStoreState, action: PayloadAction<string>) {
      const newImages = state?.selectedImages.filter(img => img?.id !== action.payload);
      return Object.assign(state, {
        selectedImages: newImages,
      });
    },
    setMode(state: GalleryStoreState, action: PayloadAction<GalleryMode>) {
      return Object.assign(state, {mode: action.payload});
    },
  },
});

export const {setGallery, setSelectedImages, addSelectedImages, removeSelectedImages, setMode, updateImage} = gallerySlice.actions;
export default gallerySlice.reducer;
