import {useState, Fragment, useEffect} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {useDropzone} from 'react-dropzone';
import {BsFillImageFill} from 'react-icons/bs';
import filter from 'lodash/filter';

import ImagePreview from '@components/Other/ImagePreview';
import {Button} from '@components/Button';

function ModalContainer({
  isOpen,
  setIsOpen,
  children,
  textClassName,
  wrapperClassName,
}: {
  isOpen: boolean;
  setIsOpen: (toggle: boolean) => void;
  children: JSX.Element;
  textClassName?: string;
  wrapperClassName?: string;
}) {
  return (
    // Use the `Transition` component at the root level
    <Transition show={isOpen} as={Fragment}>
      <Dialog onClose={() => setIsOpen(false)}>
        {/*
          Use one Transition.Child to apply one transition to the backdrop...
        */}
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className={`fixed inset-0 bg-black/30 z-[100] ${wrapperClassName}`} />
        </Transition.Child>

        {/*
          ...and another Transition.Child to apply a separate transition
          to the contents.
        */}
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0 scale-95'
          enterTo='opacity-100 scale-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100 scale-100'
          leaveTo='opacity-0 scale-95'
        >
          <Dialog.Panel
            className={
              textClassName
                ? `z-[200] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-w-4xl transform overflow-hidden rounded bg-white text-left align-middle shadow-xl transition-all ${textClassName}`
                : `z-[200] w-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-w-4xl transform overflow-hidden rounded bg-white text-left align-middle shadow-xl transition-all`
            }
          >
            {children}
          </Dialog.Panel>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
}

export default ModalContainer;
