import {useAppSelector, useAppDispatch} from '../hooks';
import {setMode, setSelectedImages, addSelectedImages, removeSelectedImages, updateImage} from './index';
import {GalleryMode, GallerySelectedImage} from './types';

export const useSelectedImages = () => {
  return useAppSelector(state => state?.gallery?.mode);
};

export const useGalleryMode = (): {
  mode: GalleryMode;
  set: (mode: GalleryMode) => void;
} => {
  const dispatch = useAppDispatch();
  return {
    mode: useAppSelector(state => state?.gallery?.mode),
    set: (mode: GalleryMode) => {
      dispatch(setMode(mode));
    },
  };
};
export const useGallerySelectedImages = (): {
  data: Array<GallerySelectedImage>;
  set: (imgs: Array<GallerySelectedImage>) => void;
  add: (img: GallerySelectedImage) => void;
  update: (img: GallerySelectedImage) => void;
  remove: (img: string) => void;
  reset: () => void;
} => {
  const dispatch = useAppDispatch();
  const data = useAppSelector(state => state?.gallery?.selectedImages);
  return {
    data,
    set: (imgs: Array<GallerySelectedImage>) => {
      dispatch(setSelectedImages(imgs));
    },
    add: (img: GallerySelectedImage) => {
      dispatch(addSelectedImages(img));
    },
    update: (img: GallerySelectedImage) => {
      dispatch(updateImage(img));
    },
    remove: (img: string) => {
      dispatch(removeSelectedImages(img));
    },
    reset: () => {
      dispatch(setSelectedImages([]));
    },
  };
};

export {};
