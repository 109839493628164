import {Routes, Route, Outlet} from 'react-router-dom';
import {RenderRoutesProps, RouteType} from './types';
import ListRoutes from './listRoute';
import NotFoundPage from '@pages/404';

export function RenderRoutes({routes = ListRoutes}) {
  const renderRoutes = (route: RouteType): JSX.Element => {
    const {path, isProtected, key, element, children} = route;
    if (!children) return <Route path={path} key={key} element={element} />;
    return (
      <Route path={path} key={key} element={element}>
        {children.map(route => {
          return renderRoutes(route);
        })}
      </Route>
    );
  };

  return (
    <Routes>
      {routes.map(route => {
        return renderRoutes(route);
      })}
      <Route path='*' element={<NotFoundPage />} />
    </Routes>
  );
}
