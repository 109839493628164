import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {EditImageModalStoreState, CurrentImageState} from './types';
import * as _ from 'lodash';

const initialState = {
  isOpen: false,
  currentImage: null,
} as EditImageModalStoreState;

const editImageModalSlice = createSlice({
  name: 'editImageModal',
  initialState,
  reducers: {
    setModal(state: EditImageModalStoreState, action: PayloadAction<boolean>) {
      return Object.assign(state, {isOpen: action.payload});
    },
    setCurrentImage(state: EditImageModalStoreState, action: PayloadAction<CurrentImageState | null>) {
      return Object.assign(state, {currentImage: action.payload});
    },
  },
});

export const {setCurrentImage, setModal} = editImageModalSlice.actions;
export default editImageModalSlice.reducer;
