import {HelmetProvider} from 'react-helmet-async';
import {ToastContainer} from 'react-toastify';
import {useQuery, useMutation, useQueryClient, QueryClient, QueryClientProvider} from '@tanstack/react-query';

import {FCProps} from '@interfaces/component';
import ReduxProvider from '@store/StoreProvider';
import ErrorBoundaryProvider from './ErrorBoundaryProvider';

const queryClient = new QueryClient();

const Providers = (props: FCProps): JSX.Element => {
  const {children} = props;
  return (
    <HelmetProvider>
      <ErrorBoundaryProvider>
        <ReduxProvider>
          <>
            <ToastContainer
              position='top-right'
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss={false}
              draggable
              pauseOnHover
              enableMultiContainer
              // containerId={'userProfile'}
              toastStyle={{borderRadius: '10px'}}
            />
            <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
          </>
        </ReduxProvider>
      </ErrorBoundaryProvider>
    </HelmetProvider>
  );
};

export default Providers;
